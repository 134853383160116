<template>
  <div class="flex justify-center rounded-lg mx-2 mt-12">
    <div class="">
      <div
        class="container mx-auto px-4 p-1 bg-sub shadow-md drop-shadow-2xl lg:p-2 rounded-md place-items-center"
      >
        <div class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#E54057"
            class="w-36 h-36"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
        <div class="">
          <p class="text-md text-white text-center font-bold">Tabbatar Dakatarwa</p>
          <div class="border border-b-4 border-color mx-auto mt-1 w-36"></div>
          <div class="m-2 mt-6 w-5/6 text-center mx-auto" dir="ltr">
            <p class="text-white md:text-md text-sm text-center">
              Kuna tabbata kuna son dakatar da biyan kuɗin shiga?
            </p>
          </div>
          <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>

          <div class="flex flex-col justify-center items-center space-y-2">
            <button
              type="submit"
              class="focus:ring-0 btn-color focus:outline-none focus:ring-pink-300 rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center w-btn text-white"
              @click="cancelSubscriptionAction"
            >
              Tabbatar da Sokewa
            </button>
            <div class="flex justify-center">
              <router-link to="/confirm/logout">
                <button
                  type="submit"
                  class="focus:ring-0 focus:outline-none focus:ring-pink-300 rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center border border-color w-btn text-white"
                >
                  Fita
                </button>
              </router-link>
            </div>
          </div>
          <div class="flex justify-center my-6">
            <router-link to="/">
              <button
                type="submit"
                class="focus:ring-0 focus:outline-none focus:ring-pink-300 rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center border border-color w-btn text-white"
              >
                Koma Baya
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SpinnerView from "@/components/SpinnerView.vue";
import { USER } from "@/constants";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      toast: useToast(),
    };
  },
  components: {
    // SpinnerView,
  },
  computed: {},
  methods: {
    ...mapActions(useAuthStore, ["cancelSubscription", "wipeUserData"]),
    async cancelSubscriptionAction() {
      let user = JSON.parse(localStorage.getItem(USER));
      await this.cancelSubscription(user?.id).then((response) => {
        if (response.data.success) {
          this.wipeUserData(response);
          this.toast.success(response.data.message);
          this.$router.push("/");
        } else {
          this.toast.error("something went wrong");
        }
      });
    },
  },
};
</script>

<style scoped>
.w-btn {
  width: 290px;
  align-self: right;
}

.bg-sub {
  background-color: #181b30;
}
</style>
