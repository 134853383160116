
<template>
  <div class="flex justify-center rounded-lg mx-2 mt-12">
    <div class="">
      <div
        class="
          container
          mx-auto
          px-4
          p-1
          bg-sub
          rounded-lg
          shadow-md
          drop-shadow-2xl
          lg:p-2
          rounded-md
          place-items-center
        "
      >
        <div class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="0.8"
            stroke="#E54057"
            class="w-36 h-36"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

        
        </div>
        <div class="">
          <p class="text-md text-white text-center">  فشل الاشتراك</p>
          <div class="border border-b-2 border-color mx-auto mt-1 w-36"></div>

          <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>

          <div class="flex justify-center my-6">
            <router-link to="/">
              <button
              type="submit"
              class="
                text-white
                focus:ring-0 focus:outline-none focus:ring-pink-300
                rounded-lg
                text-md
                w-full
                sm:w-auto
                px-5
                py-2.5
                text-center
                btn-color
                w-btn
                text-white
              "
            >
              رجوع الى الصفحة الرئيسية
            </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  
  
  
  <style scoped>
.width-height {
  width: 900px;
  height: auto;
}
.xxsm {
  width: 255px;
}
.w-btn {
  width: 290px;
  align-self: right;
}



.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}
.font-small {
  font-size: 16px;
}
</style>
  