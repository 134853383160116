<template>
  <div class="flex justify-center items-center rounded-lg mx-2 md:mt-3 relative">
    <div
      class="md:w-8/12 lg:w-4/12 w-full md:mt-3 rounded-md h-auto md:p-0 px-4 text-white md:border md:border-gray-600"
    >
      <div class="py-6 h-auto">
        <div class="flex flex-row justify-center my-1.5">
          <img src="@/assets/logo-6.png" alt="logo" class="w-20" />
        </div>
        <div class="flex flex-row justify-center my-4">
          <h1 class="text-lg font-bold text-center text-gray-100 font-Dosis">Plans</h1>
        </div>

        <div class="my-3 text-center flex justify-center">
          <p class="text-gray-400 md:text-md text-sm max-w-md text-center font-Dosis">
            Select a plan and start watching
          </p>
        </div>
        <div class="flex-items flex-col gap-4" dir="ltr">
          <div
            v-show="selectedOption == 'monthly'"
            dir="rtl"
            class="flex items-center justify-between border 'border-yellow-400 p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="svg bg-[#E54057] rounded-full w-6 h-6 text-center">
              {{ "✓" }}
            </div>
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "Monthly Plan" }}</div>
              <div class="">{{ "2,000/Month" }}</div>
              <div class="text-gray-600 text-sm">
                {{ "perfect for light viewers" }}
              </div>
            </div>
          </div>
          <div
            v-show="selectedOption == 'half_year'"
            dir="ltr"
            class="flex items-center j border 'border-yellow-400 justify-between p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "6 Months Plan" }}</div>
              <div class="">{{ "10,000/Month" }}</div>
              <div class="text-gray-600 text-sm">
                {{ "ideal for reqular viewers" }}
              </div>
            </div>
            <div class="svg bg-[#E54057] rounded-full w-6 h-6 text-center">
              {{ "✓" }}
            </div>
          </div>
          <div
            v-show="selectedOption == 'yearly'"
            dir="ltr"
            class="flex items-center justify-between border border-yellow-400 p-2 text-white rounded-md md:w-10/12 w-full"
          >
            <div class="flex flex-col gap-2" dir="ltr">
              <div class="">{{ "Annual Plan" }}</div>
              <div class="">{{ "15,000/Month" }}</div>

              <div class="text-gray-600 text-sm">
                {{ "best for binge-watchers" }}
              </div>
            </div>
            <div class="svg bg-[#E54057] rounded-full w-6 h-6 text-center">
              {{ "✓" }}
            </div>
          </div>
          <div class="relative md:w-10/12 w-full my-4">
            <email-icon class="" />
            <input
              type="email"
              class="register-inputs ring-[#E54057] border-[#E54057]"
              :placeholder="`Email `"
              v-model="user.email"
              required
            />
           
          </div>
        </div>

        <!--Actions  Buttons -->
        <div class="m-3 relative md:w-10/12 w-full md:mt-24 mt-1 mx-auto">
          <button
            @click.prevent="subscribeToPlan"
            class="inline-flex justify-center items-center gap-2 w-full btn-color text-gray-100 rounded-md py-3"
          >
            <span> Subribe Now!</span>
            <div role="status" v-show="loaded">
              <spinner-view />
            </div>
          </button>
        </div>
        <div class="m-3 relative md:w-10/12 md:mt-2 mt-1 mx-auto">
          <button
            @click.prevent="$router.go(-1)"
            class="inline-flex justify-center items-center gap-2 w-full border border-[#E54057] text-center text-[#FFF] rounded-md py-3"
          >
            <span> Back</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import EmailIcon from "@/components/svg/EmailIcon.vue";
import { selectedPlan, USER } from "@/constants";

export default {
  components: { SpinnerView, EmailIcon },
  data() {
    return {
      loaded: false,
      toast: useToast(),
      yearly: null,
      selectedOption: localStorage.getItem(selectedPlan),
      // this plan id for all the current plans for now
      user: {
        planId: "PLN_0u92fqko67gr27l",
        email: null,
        userId: null,

      },
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["subscribeAction"]),
    async subscribeToPlan() {
      this.loaded = true;
      await this.subscribeAction(this.user).then((response) => {
        if (response.data.status) {
          window.location.href = response.data.data.authorization_url;
        }
      });
      this.loaded = false;
    },
  },
  created() {
    let userdata = JSON.parse(localStorage.getItem(USER));
    this.user.userId = userdata.id;
    this.user.email = userdata.email;
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.tab {
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #fff;
  border-radius: 20px;
  color: #e54057;
}
</style>
