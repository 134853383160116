import axios from 'axios';
const BASE_URL = 'https://api.africaplus.tv/api';

/***
 * this function to create axios instance 
 * set up the request interceptor for the axios instance
 * @param url |default baseurl
 * 
 * @returns  an axios instance for the desired url
 */
const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Accept": 'application/json',
      "Content-Type": 'application/json'
    }
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
}

export const HTTP = createAxiosInstance(BASE_URL);
export const nasna = createAxiosInstance(BASE_URL);



