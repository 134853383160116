import { createRouter, createWebHistory } from 'vue-router'
import { routes } from "./routes"
// import { AUTHTOKEN } from '@/constants';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, /* eslint-disable */
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  },
})
// nasna

// router.beforeEach(async (to, from, next) => {
//   /* eslint-disable */
// //https://watch.nasna.tv/user/check?session_id=%7BCHECKOUT_SESSION_ID%7D


//   if (to.meta.auth && authenticated === null) {
//     return next({ name: '/user/subscribe' })
//   } else if (to.meta.auth && authenticated != null) {
//     next()
//   } else if (to.meta.auth == false) {
//     next()
//   } else {
//     next()
//   }

// })


export default router
